import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';



const resources = {
  en: {
    translation: {
      support: 'Drag and drop (.png/.jpg/.gif/.mp4) files here',
      down: 'Download',
      processing: 'Processing...',
      how: 'What is the function of modifying MD5 of MP4 video/picture?',
      how_result: 'Each short video and even each file has its own MD5, which is equivalent to the ID number of each person. If the MD5 of the uploaded short video is the same as that in the platform material library, the system will automatically determine that it is a plagiarized or copied video. This will not play an original role, so you need to use the MD5 modification tool to modify the file MD5.',
      save: 'Will the files be saved by our website?',
      save_answer: 'The processing is all run in your browser and will not be uploaded to our website, please do not worry.',
    }
  },
  zh: {
    translation: {
      support: '将 (.png/.jpg/.gif/.mp4) 文件拖放到此处',
      down: '立即下载',
      processing: '处理中...',
      how: '修改MP4视频/图片的MD5有什么作用?',
      how_result: '每个短视频乃至每个文件都有自己的MD5, 相当于每个人的身份证号码一样, 如果上传的短视频md5和平台素材库里面的相同, 系统会自动判定这是抄袭或者复制的视频, 这样起不到原创的作用, 所以需要用MD5修改工具修改文件MD5。',
      save: '文件会被我们网站保存吗？',
      save_answer: '处理过程均在您的浏览器运行，不会上传到我们网站，请放心使用。',
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
