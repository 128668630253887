import React, { useState, useEffect } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';


function compressImage(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const maxSize = 1024;
        let width = image.width;
        let height = image.height;
        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
          }
        } else {
          if (height > maxSize) {
            width *= maxSize / height;
            height = maxSize;
          }
        }
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(image, 0, 0, width, height);
        canvas.toBlob((blob) => {
          resolve(blob);
        }, file.type);
      };
      image.src = reader.result;
    };
    reader.readAsDataURL(file);
  });
}

function compressVideo(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      const buffer = new Uint8Array(reader.result);
      const newBuffer = new Uint8Array(buffer.length + 1);
      newBuffer.set(buffer);
      newBuffer[buffer.length] = 0;
      resolve(new Blob([newBuffer], { type: 'video/mp4' }));
    };
    reader.readAsArrayBuffer(file);
  });
}

const supportedFormats = {
  'image/png': compressImage,
  'image/jpeg': compressImage,
  'image/gif': compressImage,
  'image/bmp': compressImage,
  'image/tiff': compressImage,
  'video/mp4': compressVideo,
};

function Md5() {
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [processedFileCount, setProcessedFileCount] = useState(0);
  const [zipReady, setZipReady] = useState(false);

  const handleFileInput = (event) => {
    const files = Array.from(event.target.files).filter((file) =>
      Object.keys(supportedFormats).includes(file.type)
    );
    setSelectedFiles(files);
    setProcessedFileCount(files.length);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files).filter((file) =>
      Object.keys(supportedFormats).includes(file.type)
    );
    setSelectedFiles(files);
    setProcessedFileCount(files.length);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDownloadClick = async () => {
    if (!selectedFiles || selectedFiles.length === 0) {
      alert(t('select_files'));
      return;
    }
    const zip = new JSZip();

    for (const file of selectedFiles) {
      const compressMethod = supportedFormats[file.type];
      const compressedBlob = await compressMethod(file);
      zip.file(file.name, compressedBlob);
    }

    const zipBlob = await zip.generateAsync({ type: 'blob' });
    saveAs(zipBlob, 'compressed_files.zip');

    setProcessedFileCount((prev) => prev + selectedFiles.length);
  };

  useEffect(() => {
    if (processedFileCount === selectedFiles.length) {
      setZipReady(true);
    } else {
      setZipReady(false);
    }
  }, [processedFileCount, selectedFiles.length]);

  return (
    <div className="the_all">
      <div className="app_head">
        <div className="menu">
          <div className="logo"><span></span></div>
        </div>
        <div onDrop={handleDrop} onDragOver={handleDragOver} className="the_drop">
          <div className="drop_file"></div>
          <input type="file" multiple accept=".png,.jpg,.jpeg,.gif,.bmp,.tiff,.mp4" onChange={handleFileInput} />
          <div className="text">{t("support")}</div>
        </div>
      </div>
      <div className='download'>
        {zipReady ? (
          <button onClick={handleDownloadClick}>{t('down')}</button>
        ) : (
          <button disabled>{t('processing')}</button>
        )}
      </div>

      <div className="intro_black">
        <h4>{t('how')}</h4>
        <p>{t('how_result')}</p>
        <h4>{t('save')}</h4>
        <p>{t('save_answer')}</p>
      </div>

      <div className="foot">
        <p>All Rights Reserved &copy; Reddon. <a href='#1'>Privacy Policy</a></p>
        <p>reddon.us</p>
      </div>
    </div>
  );
}

export default Md5;